import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from '../utils/component-animation';
import { I18nService } from '../services/i18n.service';

@Component({
  selector: 'app-locale-loader',
  templateUrl: './locale-loader.component.html',
  styleUrls: ['./locale-loader.component.css'],
  animations: [FadeAnimation],
})

export class LocaleLoaderComponent implements OnInit {

  constructor(
    public _i18nService: I18nService,
  ) { }

  ngOnInit() {
  }

}
