export class OutageCardModel {
    cards: Array<OutageItemModel> = [];

    parseAndInit(rawAdCard: any) {
        let cardBody: Array<any> = rawAdCard.content.body;
        let newCard: OutageItemModel;
        let currText: string;
        let currTextBlock: Array<string> = [];
        let currTitle = null;

        for (let i = 1; i < cardBody.length; i++) {
            currText = cardBody[i].text;

            if (currText.includes('-------------')) {
                // End of Card
                newCard = new OutageItemModel(currTitle, currTextBlock);
                currTitle = null;
                currTextBlock = [];
                // Add the card to cards array
                this.cards.push(newCard);
                continue;
            }

            if (!currTitle) {
                currTitle = currText;
                continue;
            }

            currTextBlock.push(currText);
        }

        // Add the last processed card into the cards array.
        this.cards.push(new OutageItemModel(currTitle, currTextBlock));
    }


    constructor(rawAdCard) {
        this.parseAndInit(rawAdCard[0]);
    }
}


export class OutageItemModel {
    constructor(
        public title: string,
        public bodyTexts: Array<string>,
    ) { }
}
