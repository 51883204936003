export class FormattedTextModel {
    public rawText: string;
    public texts: Array<string> = [];
    public links: Array<string> = [];
    public videoLinks: Array<string> = [];


    private parseText(){
        let re = /Link\:\s(\S)*/gi;
        let matches = this.rawText.match(re);

        if(matches){
            this.links = matches;
            this.trimLinks();
        }

        this.texts = this.rawText.replace(re, '*$*').trim().split('*$*');
        // console.log("regex result: ", this.links, this.texts);
    }


    private trimLinks(){
        if(this.links.length > 0){
            this.links = this.links.map( link => {
                return link.replace("Link: ", "");
            } );
        }
    }


    constructor(text){
        this.rawText = text;
        this.parseText();
    }
}
