import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root'
})
export class I18nService{

  public isLocaleLoaded: boolean = null;
  private localeTextMap: any;
  private langList: Array<string> = [];


  public async setLocale(localeId: number) {
    // if (localeAbv === this.getLocale()) {
      //   return;
      // }
    const localeAbv = this.langList[localeId];
    this.isLocaleLoaded = false;
    console.log(`Changing locale to "${localeAbv}"`);

    await localStorage.setItem("localeId", localeId.toString());
    this.loadLocaleTexts(localeAbv)
      .subscribe(
        data => {
          if (data) {
            console.log('Locale texts: ', data);
            this.localeTextMap = data;
            this.isLocaleLoaded = true;
            this.postLocaleChange(localeAbv);
          } else {
            console.log('Error: Locale-specific texts not found!');
          }
        },
        error => {
          console.log('Error: Could not load locale-specific texts!', error);
        }
      );
  }


  private getLocaleId(): number {
    return parseInt(localStorage.getItem("localeId"));
  }


  // public getLocaleText(textKey: string) {
  //   const text = this.localeTextMap[textKey];
  //   const defaultText = "*_*";

  //   if (text) {
  //     return text;
  //   } else {
  //     return defaultText;
  //   }
  // }


  public getLocaleTextsForComponent(componentName: string) {
    const textMap = this.localeTextMap[componentName];

    if (textMap) {
      return textMap;
    }
    return null;
  }


  private loadLocaleTexts(localeAbv: string) {
    let url = `/assets/i18n/locale-${localeAbv}.json`;
    console.log(`Loading locale from url: ${url}`);

    return this._http.get<any>(url);
  }


  public setLanguages(langs: Array<any>) {
    langs.map(item => {
      let newLang = (item.availableLang) ? item.availableLang : item;
      if(!this.langList.includes(newLang)){
        this.langList.push(newLang);
      }
    });
    localStorage.setItem('localeList', this.langList.join('|'));
    // console.log(`Supported languages: ${this.langList}`);
  }


  public clearLanguages(){
    this.langList = [];
    localStorage.removeItem('localeList');
  }


  public async setDefaultLocale() {
    if (this.langList && this.langList.length > 0) {
      await this.setLocale(0);
    } else {
      console.log(`Error: Languages NOT set!!`);
    }
  }


  public getSupportedLangs(){
    return this.langList;
  }


  private postLocaleChange(localeAbv: string) {
    if (!this._appStateService.dlObj) {
      return;
    }

    this._appStateService.dlObj.postActivity({
      from: { id: this._appStateService.username },
      name: 'LocaleChange',
      type: 'event',
      value: localeAbv
    })
      .subscribe((data) => {
        console.log("SUCCESS: Locale change requested!");
      });
  }


  constructor(
    private _http: HttpClient,
    public _appStateService: AppStateService,
  ) {
    // Load locale info if present in LocalStorage.
    // This is required when the page is reloaded.
    const localeList = localStorage.getItem('localeList');
    if(localeList){
      this.langList = localeList.split('|');

      const selectedLocaleId = this.getLocaleId();
      if(selectedLocaleId !== null){
        this.setLocale(selectedLocaleId)
      }
    }
    
    // For local dev only!!
    // this.setLanguages(['en']);
    // this.setLocale(0);
  }
}
