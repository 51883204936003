
import { DirectLine, CardAction, IBotConnection, User } from '../services/botframework-directlinejs';
import { AdaptiveCard, Action, HostConfig, IValidationError, OpenUrlAction, SubmitAction } from "adaptivecards";
import { IAction, IAdaptiveCard, IOpenUrlAction, IShowCardAction, ISubmitAction } from 'adaptivecards/lib/schema';
import { Conversation, hostConfig } from './chat';
import { RenderCards } from './RenderCards';

export interface BotFrameworkCardAction extends CardAction {
  __isBotFrameworkCardAction: boolean
}

export interface Props {
  className?: string,
  hostConfig: HostConfig,
  jsonCard?: IAdaptiveCard,
  nativeCard?: AdaptiveCard,
  onImageLoad?: () => any,
}

export class RenderMessages {

  static renderMsg: RenderMessages;

  public renderBotMessages(message: any, directLineObj: any, userName: any) {
    let renderMsg = new RenderCards();
    let self = this;
    if (message.text != undefined) {
      setTimeout(() => {
        renderMsg.renderText(document.createTextNode(message.text), message.id)
      }, 100);
    }
    if (message.attachments != undefined) {
      let cardDetails = renderMsg.filterMessage(message);
      let adaptiveCard = cardDetails.adaptiveCard;
      let card = cardDetails.cardData;
      adaptiveCard.onExecuteAction = (action) => self.onExecuteAction(action, directLineObj, userName);
      adaptiveCard.parse(card);
      setTimeout(() => {
        const custHtml001 = document.getElementsByClassName("custBot");
        let renderedCard = adaptiveCard.render();
        if (cardDetails.cardType === "customAdaptiveCard") {
          renderMsg.renderCustomAdaptiveCard(renderedCard, message.id);
        } else {
          renderMsg.renderAdaptiveCard(renderedCard, message.id);
        }
      }, 100);
    }
  }



  public onExecuteAction(action: Action, directLineObj: any, userName: any) {
    let from: User;
    let self = this;
    if (action instanceof OpenUrlAction) {
      window.open(action.url);
    } else if (action instanceof SubmitAction) {
      if (action.data !== undefined) {
        if (typeof action.data === 'object' && (action.data as BotFrameworkCardAction).__isBotFrameworkCardAction) {
          const cardAction: any = (action.data as BotFrameworkCardAction);
          window.open(cardAction.value);
        } else {
          const text = (typeof action.data === 'string') ? action.data as string : undefined;
          const value = (typeof action.data === 'object') ? action.data as Object : undefined;

          var testVal = typeof action.data === 'string' ? 'imBack' : 'postBack';
          if (typeof action.data === 'string') {
            self.sendMessage(action.data, from, 'Un');
          }
          else {
            self.sendPostBack(text, value, from, 'Un', directLineObj, userName);
          }
        }
      }
    }
  }

  private sendMessage = (text: string, from: User, locale: string) => ({
    type: 'Send_Message',
    activity: {
      type: "message",
      text,
      from,
      locale,
      textFormat: 'plain',
      timestamp: (new Date()).toISOString()
    }
  });

  private sendPostBack = (text: string, value: Object, from: User, locale: string, directLineObj: any, userName: string) => {
    directLineObj.postActivity({
      from: { id: userName },
      type: "message",
      text,
      value,
    }).subscribe(id => {
      console.log("success sending postBack", id)
    }, error => {
      console.log("failed to send postBack", error);
    });
  }

  public scrollToBottom() {
    setTimeout(function () {
      let div = document.getElementById("chatScroll");
      div.scrollTop = div.scrollHeight;
    }, 100);
  }

  public updateHTML(id: any, cls: string) {
    setTimeout(() => {
      let isUserSent = id;
      const msgHtml = document.getElementsByClassName(cls);
      var span = msgHtml[(msgHtml.length) - 1];
      if (span != undefined) {
        span.setAttribute("id", id);
        document.getElementById(id).style.display = "none";
         return isUserSent++;
      }
    }, 200);
  }


}

