import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../services/app-state.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.css']
})
export class IframeModalComponent implements OnInit {

  public getIframeSrc(){
    let url = this._appStateService.urlIframe;
    console.log(`Loading iframe: ${url}`);
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  public closeIframe(){
    this._appStateService.isIframeShown = false;
  }


  constructor(
    public _appStateService: AppStateService,
    private _sanitizer: DomSanitizer,
  ) { }


  ngOnInit() {
  }

}
