import { trigger, state, transition, animate, style, query, group, stagger, sequence } from "@angular/animations";

export const chatBubbleAnimation = trigger( 'chatBubbleAnimation', [
    transition( ':enter', group([
        query( ':self', [
            style({
                height: '0px',
                padding: '0px',
                margin: '0px',
                opacity: '0.0',
            }),
            animate('0.2s ease-out')
        ]),

        query( '.bubble.anim-user', [
            style({
                transform: 'translateX(100px)',
                opacity: '0.0',
            }),
            animate('0.3s 200ms ease-out')
        ], {optional: true}),

        query( '.bubble.anim-bot', [
            style({
                transform: 'translateX(-100px)',
                opacity: '0.0',
            }),
            animate('0.3s 200ms ease-out')
        ], {optional: true}),

        query( '.bubble.anim-bot .anim-card-group .anim-card', [
            style({
                transform: 'translateX(-10px)',
                opacity: '0.0',
            }),
            stagger('0.3s', animate('0.7s 200ms ease-out'))
        ], {optional: true}),

        // query( '.bubble.anim-bot .anim-container', [
        //     style({
        //         height: '0px',
        //         borderWidth: '0px',
        //         marginBottom: '0px',
        //     }),
        //     animate('0.2s 1000ms ease-out')
        // ], {optional: true}),

        // query( '.bubble.anim-bot .anim-card-group .anim-card-more', [
        //     style({
        //         transform: 'translateX(-10px)',
        //         opacity: '0.0',
        //     }),
        //     stagger('0.3s', animate('0.5s 200ms ease-out'))
        // ], {optional: true}),

        // query( '.bubble.anim-user .content', [
        //     style({
        //         transform: 'scale(0.3,0.3)',
        //         transformOrigin: 'right', 
        //         // opacity: '0.0',
        //     }),
        //     animate('3s 200ms ease-in-out')
        // ], {optional: true}),

        // query( '.bubble.anim-bot .content', [
        //     style({
        //         transform: 'scale(0.3,0.3)',
        //         transformOrigin: 'left', 
        //         // opacity: '0.0',
        //     }),
        //     animate('3s 200ms ease-in-out')
        // ], {optional: true}),

        // query( '.icon.anim-user::after', [
        //     style({
        //         transform: 'scale(0.3,0.3)',
        //         transformOrigin: 'bottom left', 
        //         opacity: '0.0',
        //     }),
        //     animate('3s 200ms ease-in-out')
        // ], {optional: true}),
    ]) ),

    // transition( ':leave', group([
    //     query( '.bubble', [
    //         style({
    //             transform: 'scale(0.0,0.0)',
    //             opacity: '0.0',
    //             height: '0px',
    //         }),
    //         animate('0.5s ease-in-out')
    //     ]),
    // ]) )

    transition( ':leave', group([
        query( ':self', [
            style({
                height: '0px',
                padding: '0px',
                margin: '0px',
                opacity: '0.0',
            }),
            animate('0.2s ease-out')
        ]),
    ])),
] );


export const botAvatarAnimation = trigger( 'botAvatarAnimation', [
    transition( ':leave', [
        animate( '400ms ease',
            style({
                transform: 'translate(50px, -20px) scale(0.0, 0.0)',
                transformOrigin: 'right',
            }) )
    ] )
] );


export const userAvatarAnimation = trigger( 'userAvatarAnimation', [
    transition( ':leave', [
        animate( '400ms ease',
            style({
                transform: 'translate(-50px, -20px) scale(0.0, 0.0)',
                transformOrigin: 'left',
            }) )
    ] )
] );


export const cardItemAnimation = trigger( 'cardItemAnimation', [
    transition( ':enter', group([
        query( ':self', [
            style({
                height: '0px',
                padding: '0px',
                margin: '0px',
                opacity: '0.0',
                // transform: 'translateY(80px)',
                // transform: 'scale(0.5,0.5)',
                // transformOrigin: 'top',
            }),
            animate('0.2s ease-out')
        ]),

        // query( ':self', [
        //     style({
        //         opacity: '0.0',
        //         transform: 'translateY(10px)',
        //     }),
        //     animate('0.5s  ease-out')
        // ]),
    ]) ),

    transition( ':leave', group([
        query( ':self', [
            animate('0.2s ease-out', style({
                height: '0px',
                padding: '0px',
                margin: '0px',
                opacity: '0.0',
                // transform: 'translateY(80px)',
                // transform: 'scale(0.5,0.5)',
                // transformOrigin: 'top',
            }))
        ]),
    ]) )
    
] );


export const cardBtnAnimation = trigger( 'cardBtnAnimation', [
    transition( ':enter', group([
        query( ':self', [
            style({
                height: '0px',
                borderWidth: '0px',
                marginBottom: '0px',
            }),
            animate('0.4s ease-out')
        ]),
    ]) ),

    transition( ':leave', group([
        query( ':self', [
            animate('0.4s ease-out', style({
                height: '0px',
                borderWidth: '0px',
                marginBottom: '0px',
            }))
        ]),
    ]) )
    
] );