import { trigger, transition, animate, style, query, group } from "@angular/animations";

export const FadeAnimation = trigger( 'fadeAnimation', [
    transition( ':enter', group([
        query( ':self', [
            style({
                opacity: '1.0',
            }),
            animate('3.2s ease-out')
        ]),
    ]) ),

    transition( ':leave', group([
        query( ':self', [
            animate('3.2s ease-out', style({
                opacity: '0.0',
            }))
        ]),
    ]) )
    
] );