export class DecoratedText {
    public decoratedTextList: Array<DecoratedTextModel> = [];


    private convertToDecoratedText(text: string) {
        // Push the original text to the array
        text = text.replace(/\*\*/gi,' ');
        let originalText = new DecoratedTextModel(text);
        this.decoratedTextList.push(originalText);

        // Resolve the different decorations inside the original text
        this.resolveTextFormatting(TextDecorationType.Small);
        this.resolveTextFormatting(TextDecorationType.Bold);
        this.resolveTextFormatting(TextDecorationType.Italics);
        this.resolveTextFormatting(TextDecorationType.Link);
        this.resolveTextFormatting(TextDecorationType.LineBreak);
    }


    private getFormattingKeys(type: TextDecorationType): Array<string> {
        let keys: Array<string> = [];
        switch (type) {
            case TextDecorationType.Bold:
                keys = '*|b_ _b|*'.split(' ');
                break;
            case TextDecorationType.Italics:
                keys = '*|i_ _i|*'.split(' ');
                break;
            case TextDecorationType.Small:
                keys = '*|s_ _s|*'.split(' ');
                break;
            case TextDecorationType.Link:
                keys = '*|a_ _a|*'.split(' ');
                break;
            case TextDecorationType.LineBreak:
                keys = '*|n_ _n|*'.split(' ');
                break;

            default:
                break;
        }

        return keys;
    }


    private resolveTextFormatting(decorType: TextDecorationType) {
        // Format structure *|s_ _s|*
        let resolvedTextList: Array<DecoratedTextModel> = [];
        let formattingKeys = this.getFormattingKeys(decorType);

        if (formattingKeys.length == 0) {
            // return if undefined keys
            console.log("Undefined TextDecorationType");
            return;
        }

        const startKey = formattingKeys[0];
        const endKey = formattingKeys[1];

        let tempSplitText: Array<string>;
        let tempSplitText2: Array<string>;
        let tempDecorations: Array<TextDecorationType>;
        let tempDecoratedText: DecoratedTextModel;

        for (let dtxt of this.decoratedTextList) {
            tempDecorations = dtxt.decorations;
            tempSplitText = dtxt.text.split(startKey);

            for (let txt of tempSplitText) {
                if (txt.includes(endKey)) {
                    // txt = txt.replace('_s|*', '').trim();
                    tempSplitText2 = txt.split(endKey);

                    // push the formatted string
                    tempDecoratedText = new DecoratedTextModel(tempSplitText2[0], tempDecorations.concat(decorType));
                    resolvedTextList.push(tempDecoratedText);

                    // push the plain string
                    if (tempSplitText2[1] != '') {
                        tempDecoratedText = new DecoratedTextModel(tempSplitText2[1], tempDecorations);
                        resolvedTextList.push(tempDecoratedText);
                    }
                } else {
                    tempDecoratedText = new DecoratedTextModel(txt, tempDecorations);
                    resolvedTextList.push(tempDecoratedText);
                }
            }
        }

        // Replace the public list with the new resolved list
        this.decoratedTextList = resolvedTextList;
    }


    constructor(text: string) {
        this.convertToDecoratedText(text);
    }
}


export class DecoratedTextModel {
    decorations: Array<TextDecorationType> = [];
    text: string;

    constructor(text: string, decor?: Array<TextDecorationType>) {
        this.text = text;
        if (decor) {
            this.decorations = decor;
        }
    }
}


export enum TextDecorationType {
    Bold, // *|b_ _b|*
    Italics, // *|i_ _i|*
    Small, // *|s_ _s|*
    Link, // *|a_ _a|*  >>  Example: "*|a_ https://www.google.com||Click here!! _a|*"
    LineBreak, // *|n_ _n|*
}