export class Conversation {

  date_new: any;
  who: boolean;
  text: any;
  id: string;
  chart: string;

  formatAMPM(strdate: string) {
    var date: Date = new Date(strdate);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var upmin: any = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + upmin + ' ' + ampm;
    return strTime;
  }

  //-- No use time. It is a javaScript effect.
  constructor(who: boolean, date_new: any, text: any, id: string) {
    this.who = who;
    if (date_new != '') {
      this.date_new = this.formatAMPM(date_new);
    }
    this.text = text;
    this.id = id;
  }
}

export const hostConfig = {
  "supportsInteractivity": true,
  "strongSeparation": {
    "spacing": 40,
    "lineThickness": 1,
    "lineColor": "#eeeeee"
  },
  "fontFamily": "Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  "fontSizes": {
    "small": 10,
    "normal": 12,
    "medium": 14,
    "large": 17,
    "extraLarge": 19
  },
  "actions": {
    "maxActions": 100,
    "separation": {
      "spacing": 8
    },
    "buttonSpacing": 8,
    "stretch": false,
    "showCard": {
      "actionMode": "inlineEdgeToEdge",
      "inlineTopMargin": 16,
      "backgroundColor": "#00000000",
      "padding": {
        "top": 8,
        "right": 8,
        "bottom": 8,
        "left": 8
      }
    },
    "actionsOrientation": "vertical",
    "actionAlignment": "left"
  },
  "backgroundColor": "#FFF",
  "image": {
    "size": "medium",
    "separation": {
      "spacing": 8
    }
  },
  "imageSet": {
    "imageSize": "medium",
    "separation": {
      "spacing": 8
    }
  },
}