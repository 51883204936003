export class MultiContentCardModel{
    public contentList: Array<MconItem> = [];

    private parseResToMcon(adCard: any){
        const contentBody = adCard[0].content.body;
        for (let item of contentBody){
            const newMconItem = new MconItem();

            if(item.type === "Image"){
                newMconItem.contentType = ContentType.Image;
                newMconItem.contentBody = item.url;
            }else{
                newMconItem.contentType = ContentType.Text;
                newMconItem.contentBody = item.text;
            }
            
            this.contentList.push(newMconItem);
        }
    }

    constructor( adCardResponse: any ){
        this.parseResToMcon(adCardResponse);
    }
}


export class MconItem {
    public contentType: ContentType;
    public contentBody: string;

    constructor(){}
}


export enum ContentType {
    Image,
    Text,
}