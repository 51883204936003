import { Injectable } from '@angular/core';
import { DirectLine } from './botframework-directlinejs';
// import { Http, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DirectLineService {

  authObj: any;
  userName: any;
  accessToken: any;
  userId: any;


  // NOTE: The connection is not being generated using a token!
  createDirectlineConnection(dlToken: string) {
    let directLineObj = new DirectLine({
      secret: dlToken,
    });
    return directLineObj;
  }


  refreshDirectLineToken(token: any) {
    let refreshUrl = 'https://directline.botframework.com/v3/directline/tokens/refresh';
    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append("Authorization", "Bearer " + token);
    // const options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    const options = {headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + token
    } };

    // this.http.post(refreshApi, token, options)
    this._http.post(refreshUrl, token, options)
      .subscribe(
      result => {
        console.log("Token refreshed!");
        // console.log("Token refreshed", result);
      },

      err => {
        console.log("Error- something is wrong!", err)
      });
  }


  constructor(
    // private http: Http
    private _http: HttpClient
  ) { }

}
