export class InfoCardModel {
    public urlImage: string;
    public urlArticle: string;
    public desc: string;


    private mapRawToModel(rawData: any){
        let dataList: Array<any> = rawData[0].content.body;

        this.urlImage = dataList[0].url;
        this.desc = dataList[1].text;

        this.urlArticle = rawData[0].content.actions[0].url;
    }


    // NOTE: rawData should only contain the "attachments" object from the original response.
    // "attachments" encapsulates the adaptive card schema.
    constructor(rawData: any){
        this.mapRawToModel(rawData);
        console.log(this);
    }
}
